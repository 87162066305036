import React from 'react';

import Navbar from './components/navbar';
import Content from './components/content';
import Footer from './components/footer';

import './Styles/footer.css';

const App = () => {
  return (
    <>
      <Navbar />
      <Content />
      <Footer />
    </>
  );
};

export default App;
