import React from 'react';
import '../Styles/content.css';

// Importando o vídeo e a imagem
import videoCristina from '../assets/videos/videocristina.mp4';
import contentgraphism from '../assets/videos/graphismbottom.gif';

const Content = () => {
  return (
    <div className="content">
        
      <div className="content-left">
        <div className="video-container">
          <video src={videoCristina} controls className="video-cristina"></video>
          <div className="cristina-info">
            <div className="name">Cristina Castilho</div>
            <div className="position">CEO da Studio Conceito Arquitetura</div>
          </div>
          <img src={contentgraphism} alt="Grafismo" className="content-image" />
        </div>
      </div>

      <div className="content-right">
        <h2>Uma mulher em um mercado dominado por homens</h2>
        <p>
          Ser uma mulher em um mercado dominado por homens foi, sem dúvida, o maior desafio no início na Studio Conceito.
          Quis clientes e fornecedores elevaram o tom de voz comigo apenas porque eu era jovem e mulher. Isso deixou uma marca que persiste até hoje.
        </p>
        <p>
          Temos uma maioria de pessoas do gênero feminino em nossa equipe, e sempre apoio-as e protejo-as, evitando ao máximo que passem pelas dificuldades que enfrentei.
        </p>

        <h2>Cuidamos mais!</h2>
        <p>
          O "Cuidamos Mais" sempre foi a essência da Studio Conceito. Cuidamos mais de nossos clientes, sempre próximos.
          Entendemos suas necessidades e, a partir disso, criamos projetos únicos e personalizados que naturalmente se refletem internamente.
        </p>
        <p>
          Começou com a criação de espaços de trabalho confortáveis, ergonômicos e criativos. Nosso RH e endomarketing estão focados no bem-estar de nossos colaboradores.
        </p>
        <p>
          O resultado disso reflete diretamente nos nossos produtos: Projetos Incríveis e Obras muito bem feitas!
        </p>

        <h2>Reconhecimento: Obtenção do selo GPTW</h2>
        <p>
          A obtenção do selo GPTW chegou para coroar nossas ações. Isso nos trouxe muito orgulho e mostra como estamos no caminho certo!
        </p>

      </div>


    </div>
  );
};

export default Content;
