import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import "../Styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a
          href="https://sconceito.com.br"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.sconceito.com.br
        </a>

        <div className="footer-social">
          <a
            href="https://www.instagram.com/sconceitoarquitetura/"
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
            <span>@sconceitoarquitetura</span>
          </a>
        </div>

        <div className="footer-social">
          <a
            href="https://www.linkedin.com/company/studio-conceito-arquitetura/"
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="social-icon" />
            <span>@studioconceitoarquitetura</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
