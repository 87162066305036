import React from 'react';
import '../Styles/navbar.css';

// Importando as imagens como variáveis
import navbarLeftImage from '../assets/images/navbarleft.jpg';
import navbarRightImage from '../assets/images/navbarright.jpg';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={navbarLeftImage} alt="Navbar Left" className="navbar-image" />
      </div>
      <div className="navbar-right">
        <img src={navbarRightImage} alt="Navbar Right" className="navbar-image" />
      </div>
    </nav>
  );
};

export default Navbar;
